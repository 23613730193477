import React, { useContext } from 'react'
import CartOverview from '../../components/cart/CartOverview'
import SEO from '../../components/generic/Seo'

const CartOverviewPage = (props: any) => {
  return (
    <>
      <SEO title="Dein Warenkorb" />
        <CartOverview />
    </>
  )
}

export default CartOverviewPage
